body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-btn-align-general .lm-IconButton::after {
  width: 32px;
  height: 32px;
}

.lm-Icon-Edit, .other-offices .lm-Icon-Trash {
  height:18px;
  width:18px;
  left: calc(50% - 9px) !important;
  top: 14px !important;
}

.breadcrumb-line, .colContainerHeader, .colContainer, .colContainer + h6 {
  padding: 0 32px;
}

.lm-LogoSafeco {
  margin-left:32px !important;
}

.faa-header-app-name {
  margin-left:2rem;
}

.faa-header-app-name > h3, .faa-header-app-name > h5  {
  margin:0;
}

.faa-header{
  background-color: #1A1446 !important;
  min-height:120px;
}

.faa-header .lm-Heading {
  color:white;
}

.faa-header .lm-Masthead {
  display:flex;
  align-items:center;
}

.faa-header .help-link {
  margin-left:auto;
  margin-right:32px;
  align-self: flex-end;
}

.faa-header .help-link svg {
  margin-right:8px;
}

.faa-header.full-bleed .lm-Masthead {
  max-width: 100%;
}

.faa-header .lm-Masthead {
  max-width: 1210px;
}