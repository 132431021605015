.agencyProfile {
    background-color: white;
}

.margin-text {
    margin-top: 4px;
}

.margin-text-4 {
    margin-top: 4px;
}

.margin-text-4:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.icon-padding{
    padding: 0 0 0 1rem;
    margin: 0;
}

.inline {
    display: inline-flex;
}

.historyIcon{
    opacity: 0.6;
}

.historyIcon:hover{
    opacity: 1;
    cursor: pointer;
}

.tableCell{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.safeco-color {
    color: #1A1446;
}

.spacerBody {
    border: 2px solid transparent;
}

.spacerRow{
    visibility: collapse;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
}

.spacerCell{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.agencyBox {
    box-shadow: 0 0 0 1.5px #1A1446;
    border-radius: 1px;
}

.agencyBox a{
    color:#06748c;
}

.table-header-box{
    box-shadow: 0 4px 8px 0;
    color:rgba(0,0,0,.2);
}

.breadcrumb-line {
    font-size: 1rem;
    line-height: 1.6em;
    letter-spacing: normal;
    font-weight: 400;
}

.breadcrumb-line svg {
    margin-left: 8px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
}

.breadcrumb-links {
    color: #06748c!important;
    text-decoration: none;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    margin-top: 1rem;
}

.breadcrumb-links:hover {
    text-decoration: underline;
}

.breadcrumb-p {
    margin: 0;
    color: #777a7c;
    display: inline-block;
    margin-top: 1rem;
}

.agents-table-incr-width{
    margin-top: 30px;
    margin-left: -0.875rem;
    width: 102.5%;
}

.agents-table-decr-width{
    margin-top: 30px;
}
.table-loading-spinner {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}
