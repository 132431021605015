.input-search {
    width : 50%;
    padding: 10px 0;
}

.table-header-box{
    box-shadow: 0 4px 8px 0;
    color:rgba(0,0,0,.2);
}

.lm-Table-cell--colHead{
    background-color: #28387E;
    color: white !important;
}

.table-header {     /*dropable?*/
    display: inline-flex;
}

.icon-padding {     /*dropable?*/
    padding: 0 2rem;
}

.margin-text {
    margin-top: 4px;
}

.margin-text-4 {
    margin-top: 4px;
}

.margin-text-4:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.agencyBox {
    box-shadow: 0 0 0 1.5px #1A1446;
    border-radius: 1px;
}

.grayOut {
    background-color:#efefef;
    color: gray;
}

.spacerBody {
    border: 2px solid transparent;
}

.spacerRow{
    visibility: collapse;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
}

.spacerCell{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.tableCell{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.noMatches {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    color: #1A1446;
}

.safeco-color {
    color: #1A1446;
}

.clearButton{
    position: absolute !important;
    right: 5px;
    top: 5px;
}
.agency-search-dropdown {
    position: absolute;
    top: 204px;
    background-color: white;
    width: 425px;
    border: 1px solid lightgray;
    z-index: 1;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    max-height: 700px;
    overflow-y: auto;
}
.agency-search-dropdown-item {
    padding: 10px 10px 0px 10px;
}
.agency-search-dropdown-item > div {
    border-bottom: 1px solid lightgray;
    padding-bottom:10px;
}
.agency-search-dropdown-item:last-child > div {
    border-bottom: none;
}
.agency-search-dropdown-item:hover {
    background-color: #f1f1f1;
    cursor:pointer;
}
.agency-search-dropdown-agency-code {
    font-size: 14px;
}
.searchBar{
    margin-top: 2rem;
    display: flex;
    margin-bottom: 1rem;
}
.searchBar .search-input > .lm-FieldGroup-field--input {
    margin-bottom:0;
}
 .search-input {
     max-width:425px !important;
     width: 100%;
     margin-right: 10px;
     margin-top: 0 !important;
 }
 .searchBar > button { /* match the button height to the search box height by removing 3 pixels to account for the buttons back shadow*/
     height:47px;
     min-height:47px !important;
     align-self: flex-end;
     margin-bottom: 10px;
 }
.agency-list-table table {
    border-collapse: separate;
    border-spacing: 0;
}

.statusBadge .lmig-Badge-background{
    width: 121px;
}

.gridRightAlign{
    text-align: right;
}
