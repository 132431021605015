
*{  
   margin: 0;  
   padding: 0;  
   box-sizing: border-box;  
   font-family: 'Roboto';  
}  
.container{  
   width: 450px;  
   display: flex;  
   justify-content: space-between;  
   flex-wrap: wrap;  
   margin: 1em 0 0 0;  
}  

.margin-content {
   margin: 30px 0px 30px 0px;
}

.center-content {
    justify-content: center;
}

.right-content {
   justify-content: right;
}

.show-error {
   text-align: left;
   display: block;
}
.hide-error {
   display: none;
}

.loading {
   margin-top: 10px;
}
.spinner {
   display: block;
   margin: 0px auto;
   width: 5%;
}
