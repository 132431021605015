.rowContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 10px;
}

.flexItemRow {
    display:flex;
    width:100%;
    padding:10px;
}

.flexItemRow .flexItem {
    width:100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.flexItemRow .flexItem:first-child {
    margin-right:10px;
    width:48%;
}

.flexItemRow .flexItem:last-child {
    margin-left:10px;
    width:48%;
}
#formField {
    margin-top: 0;
    margin-bottom: 0;
    /* width: 100%; */
}

.indentContent {
    padding-left: 30px;
}

.indentHoursContent {
    padding-left: 10px;
}
.errorMessage {
    padding-bottom: 5px;
}

#noOverlap {
    z-index: -1;
}

.pageLayoutEdit {
    display: grid;
    justify-content: center;
    grid-template-columns: 20% 60% 20%;
}

.agentFlexLayout {
    display: flex;
    flex-direction: column;
}

.agentCardSpacing {
    margin-bottom : 10px;
    padding: 0px !important;
}

.agentCardSpacing:hover, .agentCardSpacing:active {
    border-color: #06748C !important;
    border-width: 3px;
}
.linear-progress {
    height:20px;
}
.linear-progress p {
    text-align: center;
    z-index: 10000;
    position: relative;
    top: -22px;
}

#agentContainer {
    padding: 4px;
}

.agentCardFlexBox {
    display: flex;
    flex-wrap:nowrap;
    align-items: center;
    overflow: hidden;
}

.agentCardFlexBox svg:not(.MuiSvgIcon-root) {
    height:18px;
    width:18px;
    margin-right: 6px;
}

.agentCardFlexBox:hover{
    cursor:move;
}

.agentCardFlexBox .agentAvatar {
    width:80px;
    height:80px;
    margin-right: 6px;
}
.agentDetails {
    width: calc(100% - 85px );
}
span.agentName {
    text-transform: capitalize;
}

span.agentDetail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.agent-card-row {
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.hidden-agent { background-color:#e3e3e3 !important; }
.hidden-agent .MuiLinearProgress-root { background-color:#D9E8FE}
.hidden-agent #agentContainer { opacity: .5; }
.hidden-agent .lm-Icon-Info path { fill:#0061F5 }
.hidden-agent img { filter: grayscale(1); }
.hidden-agent .MuiLinearProgress-bar { opacity:.2; }
.agentImageContainer {
    width: 30px;
    height: 30px;
}

.no-access-message{
    margin:0 32px !important;
}

.agentImageContainer img {
    width: 100%;
    height: 100%;
}

.selectTime {
    box-sizing: border-box !important;
    padding: 11px 0;
    width: 145px;
}

.containerContactInfo {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: left;
    justify-content: flex-start; */
    display: grid;

    align-content: left;

    grid-template-columns: repeat(3, 1fr);
}

#formContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: -60px;
    margin-bottom: -30px;
}

#emailPhoneContainer {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 55% 44%;
}

.twoPerRow {
    display: grid;
    justify-content: space-between;

    grid-template-columns: 49% 49%;
}
.threePerRow {
    display: grid;
    justify-content: space-between;

    grid-template-columns: 39% 25% 34%;

}

.stateSelect {
    height: 100%;
    z-index: 3;
    opacity: 1;
    padding-top: 4px;
}

.colContainerHeader {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
}

.colContainer {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
}

.colFormContainer {
    margin-top: 0;
    display: table;

}

.agency-toggle {
    margin-top:0px;
}

.hourSpacing {
    margin: 0 0 1rem 0;
}

.spacing {
    margin: 0 20px;
}

#alignSpacing {
    margin-right: 113px;
}

#spacing {
    margin: 0 1rem;
}
.select {
    width: 7rem;
}

#newTopic {
    margin-left: 90px;
}

.edit-profile-banner-section .lm-Heading {
    background-color: #1A1446;
    color: white;
    margin-top:0px;
}

.edit-profile-banner {
    display:flex;
}

.edit-profile-banner .edit-profile-banner-section {
    margin: 0px 30px;
}
.profile-photo-container {
    max-width: 130px !important;
    max-height: 130px !important;
    width:130px;
    height: 100%;
}
.profile-photo-image {
    width:100%;
}
.profile-photo-image:hover {
    cursor:pointer;
}
#card {
    border: none;
}

#max-content {
    width: 8rem;
}

#yesnobutton {
    padding: 0 10px;
}

#inline {
    display: inline-grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: 7px;
}

#checkboxGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
    justify-content: start;
    margin-left: 7px;
}

.agencyPageButtons {
    padding-top: 13px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.textarea-general {
resize: none;
font-size: 18px;
font-family: 'Roboto';
padding: 16px;
}

#paddingHeader {
    padding: 19px 0
}

#modalButtons {
    padding-left: 215px;
    display: grid;
    grid-template-columns: 10rem 10rem;
    padding-top: 27px;
}

#modalButtonsEdit {
    padding-left: 57px;
    display: grid;
    grid-template-columns: 10rem 10rem 10rem;
    padding-top: 27px;
}

#otherHours {
    display: grid;
    grid-template-columns: 16rem 7rem;
    justify-content: inherit;
    padding-top: 13px;
}

.sectionHeader {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

}

.edit-btn-align-general {
    float: right;
    margin-top: -75px;
}




.product-and-languages-wrapper {
    display: flex;
}

/* .filed-group-margin-items {
    margin-top: -18px;
} */
.icon-text-modal2 {
    margin-top: 11px;
    margin-left: -40px;
}

.icon-text-tile {
    margin-top: 11px;
    margin-left: 0;
}

.breadcrumb-line {
    font-size: 1rem;
    line-height: 1.6em;
    letter-spacing: normal;
    font-weight: 400;
}

.breadcrumb-line svg {
    margin-left: 8px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
}

.display-article {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.breadcrumb-links {
    color: #06748c!important;
    text-decoration: none;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    margin-top: 1rem;
}

.breadcrumb-links:hover {
    text-decoration: underline;
}

.breadcrumb-p {
    margin: 0;
    color: #777a7c;
    display: inline-block;
    margin-top: 1rem;
}

.crop-area {
    position: absolute;
    cursor: crosshair;
    max-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.crop-slider {
    padding-left: 284px;
    padding-top: 65px;
}

.ReactCrop__crop-selection {
    box-sizing: border-box !important;
    display: block;
    position: absolute;
    transform: translate3d(0, 0, 0);
    cursor: move;
    touch-action: none;
    border: 1px dashed #fff;
}

#resetCanvas {
   display: none;
}

.css-5xe99f-MuiLinearProgress-bar1{
    /*background-color:rgb(26, 20, 70) !important;*/
    height:inherit;
}

.checklistToggle{
    margin-right: 5px;
    margin-top: -5px;
    margin-left: -3.85rem;
    margin-bottom: -50px;
}

.checklistSpacing{
    margin-left: 2rem;
    margin-bottom : 7px;
}

.profileChecklist{
    height: auto;
    padding:0px !important;
    position: sticky !important;
    top:0;
}

.agentListCard {
    padding:0px !important;

}

.disableBadge:hover{
    cursor: not-allowed;
}

.disableBadge .lmig-Badge-background{
    width: 10.22rem;
}

.checklistBadge:hover{
    cursor: pointer;
}

.checklistBadge .lmig-Badge-background{
    width: 10.22rem;
}

.agentlist-header-background{
    background-color: #1A1446;
    color: #1A1446 !important;
    box-shadow: 0 1px 4px 0;
    margin-bottom:10px;
    height: 85px;
    display: flex;
    justify-content: center;
}

.agentlist-header-text{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    top: 6px;
    position: relative;
    color: #fff !important;
    padding: 19px 0;
    text-align: center;
}

.checklist-title-background{
    background-color: #1A1446;
    color: #1A1446 !important;
    box-shadow: 0 1px 4px 0;
    margin-bottom:10px;
    height:85px;
}

.checklist-title-text{
    margin-top: 0px;
    margin-bottom: 0px;
    top: 6px;
    position: relative;
    color:white;
    padding: 19px 0;
    text-align: center;
}

.checklist-header-background{
    background-color: #1A1446;
    color: #1A1446 !important;
    box-shadow: 0 1px 4px 0;
    height:40px;
    margin-bottom:10px;
}

.checklist-header-text{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    top: 6px;
    position: relative;
    color:white !important;
}

.add-item-link {
    display:flex;
    align-items:center;
    text-decoration:none !important;
    color: #06748c;
}

.add-item-link:hover {
    cursor:pointer;
}

.add-item-link button + * {
    padding:1rem;
}
.add-item-box {
    border: 5px solid #02455387;
}

.lm-IconButton--white:focus::before {
    background:white !important;
}
.edit-profile-banner img:hover {
    cursor:pointer;
}
.add-item-box.top-banner-item-box {
    min-height: 130px;
}
.add-item-box.top-banner-item-box > span > button {
    margin-top:.5rem;
}
.add-item-box {
    position:relative;
    min-height: 170px;
}

.add-item-box > span > button {
    margin-top: 1rem;
}

.add-item-box label {
    position: absolute;
    top: 0;
    height: 100%;
    padding: 4rem 1rem 1rem 1rem;
    line-height: 1.4rem;
}

.add-item-box .add-item-link {
    flex-direction: column;
    margin:auto;
    text-decoration:none;
    text-align: center;
    font-weight: bold;
}

.add-item-box:hover, .add-item-box:hover button, .add-item-box:hover label {
    cursor:pointer;
}

.add-item-box:hover .lm-IconButton--white::before, .add-item-box:hover .lm-IconButton--white::after,
.add-item-link:hover .lm-IconButton--white::before, .add-item-link:hover .lm-IconButton--white::after {
    background: #f5f5f5;
}

.trashable {
    display:flex;
    align-items:center;
}

.trashable div:first-child {
    flex-grow:1;
}

.trashable svg {
    margin:1rem;
}

.trashable svg:hover {
    cursor:pointer;
}

.stateSelect {
    padding-top:0px;
}

.stateSelect .faa__control {
    height:50px;
}

.office-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.office-location .lm-Icon-Trash {
    width:18px;
    height:18px;
    left: calc(50% - 9px) !important;
    top: 14px !important;;
}

.review-row {
    display:flex;
    padding-bottom:10px;
    align-items:center;
}

.review-text {
    padding-left:10px;
}

:root {
    --star-size: 16px;
    --star-color: #d5d5d5;
    --star-background: #ffcf00;
    --rating: 0;
  }

.rating {
    display: flex;
    align-items: center;
  }

.rating:after {
    color: #ffcf00;
    font-size: 24px;
    display: inline;
    align-items: center;
    line-height: 0;
}

.agency-page-reviews .rating:after {
    font-size:36px;
  }

.stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    line-height: 1;
}

.stars::after {
    content: "★★★★★";
    letter-spacing: 1px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tile-stars {
    font-size: 20px;
    top: -5px;
    position: relative;
}

.review-name-row {
    display: flex;
    align-items: center;
    color: #343741 !important;
}
.review-name-row:hover .review-author-name {
    text-decoration: underline;
}
.review-name-row:hover {
    text-decoration: none;
}
.review-name-row img {
    margin-right: 1.5rem;
    display: block;
    max-width: 230px;
    max-height: 60px;
    width: auto;
    height: auto;
}

.lm-Modal .review-card {
    width: 95%;
}
.review-card:last-child {
    margin-bottom:1rem;
}
.review-card {
    margin-top: 1rem;
}

.review-card-text {
    margin-top: 1rem;
}
.review-card-text:empty {
    display:none;
}
.review-star-rating {
    font-size: 24px;
}

.review-row p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0px
}

.capitalize {
    text-transform: capitalize
}

.review-card .lm-Card-children {
    margin-bottom: 0px;
}

.review-top-row {
    display:flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.review-date {
    margin-left:1rem;
    white-space:nowrap;
}

@media only screen and (max-width: 400px) {
    .review-date {
        margin-left:0px;
    }
}

.googleReviewModal .lm-Modal-wrapper {
    padding-right: 10px;
}

.googleReviewModal .lm-ButtonClose {
    right: 10px !important;
}

.subtitle {
    text-decoration: underline;
}

.header-picture-box {
    min-height: 151.5px;
    width: 110px !important;
    border: 2px solid !important;
    border-radius: 15px;
    border-color: #28387E !important;
    background-color: white;
}

.overlay{

    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
.processing{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
