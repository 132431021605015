.space-input {
    margin-left: 10px;
    margin-right: 10px;
}

.space-input-right {
    margin-right: 10px;
}
.safeco-color {
    color: #1A1446;
}

.textarea-general {
    resize: none;
    font-size: 18px;
    font-family: 'Roboto';
    padding: 16px;
}

.icon-text-general {
    margin-left: 2.5px;
    display: flex;
}
.icon-text-photo {
    margin-top: 11px;
    margin-left: 2.5px;
}
.icon-text-modal {
    margin-top: -6px;
    margin-left: -40px;
}
.icon-text-long {
    margin-top: 12px;
    margin-left: 2.5px;
}

.indent-content-inner {
    padding-left: 12px;
    display: flex;
}

.closed-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.show-more-general {
    text-decoration:none !important;
    margin-left: -10px !important;
}
.link-general {
    text-decoration:none !important;
}

.carrot-icon-margin {
    margin-left: 5px;
}

.row-margin-general {
    margin-top: 18px;
}

.image-profile {
    border: solid 2px #1A1446;
    border-radius: 6%;
}
