.cls-1{fill:#00ac47;}
.cls-2{fill:#5bb974;}
.cls-3{fill:#00832d;}

.preferred-contact-info .lm-Card-body {
    margin-top:0px;
}

.fieldWidth{
    max-width:250px;
}

.fieldStyle{
    max-width:98%;
    word-wrap:break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}