.imageContainer {
    display: flex;
    flex-flow: row wrap;
    width: 35rem;
    height: auto;
    justify-content: left;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;

}

.tileImageContainer {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: auto;
    margin-top: 10px;
}


.imageContainer .imageCardContainer {
    width: 10vh;
    height: 10vh;
}

input[type="file"] {
    display: none;
}

.errorWarningContainer {
    margin-top: 16px;
    margin-bottom: 32px;
}

.errorWarningContainer .lmig-Notification:not(:first-child) {
    margin-top:10px;
}

.add-item-box, .photo-box {
    width: calc(33% - 6px);
}

.photo-box {
    border: 2px solid #02455387;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position:relative;
    max-height: 200px;
}

.tileImageContainer > div:not(:nth-child(3n)) {
    margin-right:10px;
}

.tileImageContainer > div {
    margin-bottom: 10px;
}

.photo-box .gallery-popover:not(.lmig-Popover-overlay) {
    margin-left:auto;
    height:20px;
}

.photo-box button {
    text-decoration: none;
}

.photo-box .gallery-popover svg {
    top: -7px;
    right: 0px;
    left: calc(50% - 25px);
}

.photo-box .gallery-popover .lmig-Popover-overlay-tip {
    left: 63px !important;
}

.photo-box .lmig-Popover-overlay {
    left: -85px !important;
    transform: translateY(-12px) !important;
    width: 116px;
    z-index: 1;
}

.photo-box .lmig-Popover-overlay li:not(:last-child) {
    padding-bottom: 5px;
    border-bottom: 1px solid #e5e2e2;
    margin-bottom: 8px;
}

.photo-box .gallery-popover ul {
    list-style: none;
    font-size:16px;
}

.lm-Card-body .photo-box > img {
    margin: auto;
    max-height: 130px;
}

.photo-box .lm-Icon-SeeMore {
    transform: rotate(90deg);
    margin-top: -8px;
    float: right;
}

.photo-box button.lmig-Popover-trigger:before, .photo-box button.lmig-Popover-trigger:after{
    display:none;
}

.photo-box .replace-button label:hover {
    cursor:pointer;
}

.agency-profile-picture {
    white-space: nowrap;
    padding-top: 0.5rem;
    color: #02455387;
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 1;
    margin-top: auto;
}

.hide-popover .lmig-Popover-overlay-content, .hide-popover .lmig-Popover-overlay-tip {
    display:none;
}

.crop-image-modal-content {
    display:flex;
    position:relative;
    justify-content: center;
}
#crop-image.zoom {
    width:250px;
    height:250px;
}
#crop-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.agency-photo-modal-buttons {
    padding-top: 2rem;
    float: right;
}

.agency-photo-modal img {
    display: flex;
    padding-bottom: 2rem;
    max-width: 366px;
    max-height: 310px;
    margin:auto;
}

.agency-photo-modal .lm-Modal-wrapper {
    max-width: max-content !important;
    min-width:200px;
}