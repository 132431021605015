#contactInfoCard {
    background-color: #1A1446;
    color: white;
    padding:32px;
    display: flex;
}


.containerContactInfo {
    display: grid;
    grid-template-columns: repeat(3, 1.5fr);
}

.font {
    font-style: italic;
    margin-bottom: 5px;
    margin-top: 5px;
}

.text-margin {
    margin-left: 15px;
}

.settings-info {
    display: flex;
    margin-left: auto;
    align-items: flex-start;
}
.settings-info .lmig-Popover {
    top: -18px;
    position:relative;
}
.settings-info .lmig-Popover-overlay-content {
    color:#343741;
}
.settings-text {
    color:white;
    opacity:.8;
    margin-right: 5px;
    font-size: 14px;
}

.settings-info .lm-IconButton--transparent:hover::before {
    background: rgb(245 245 245 / 13%);
}