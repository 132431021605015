
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Roboto';
}
.container{
   width: 450px;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   margin: 1em 0 0 0;
}
.checkbox{
   float: right;
   height: 40px;
   position: relative;
   margin: 0.5em 0;
}
.checkbox-large {
    width: 260px;
    height: 40px;
    position: relative;
    margin: 0.5em 0;
}
.custom-size {
    height: 50px;
}
.checkbox input{
   position: absolute;
   cursor: pointer;
   width: 100%;
   height: 100%;
   z-index: 2;
   appearance: none;
   -webkit-appearance: none;
}
.box{
   width: fit-content;
   height: 100%;
   padding: 0px 10px;
   z-index: 1;
   background: #ffffff;
   border: 2px solid #E6E6E6;
   border-radius: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   transition: all 0.5s;
   margin-right: 5px;
}
.box-with-icon{
    width: fit-content;
    padding: 0px 10px;
    height: 100%;
    z-index: 1;
    background: #ffffff;
    border: 2px solid #E6E6E6;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s;
    margin-right: 5px;
}

.box p{
   transition: all 0.5s;
}
.box-with-icon p{
    transition: all 0.5s;
}
.checkbox input:checked ~ .box {
   background: #99E5EA;
   border: 2px solid #28A3AF;
   color: #343741;
}
.checkbox input:checked ~ .box-with-icon {
    background: #99E5EA;
    border: 2px solid #28A3AF;
    color: #343741;
 }

.product-icon-wrapper {
    background-color: #ffd000;
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-left: -11px;
    transform: scale(.9);
  }
.product-icon-wrapper-small {
    background-color: #ffd000;
    width: 26px;
    height: 26px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transform: scale(.9);
  }

.center-content {
    justify-content: center;
}

.right-content {
   justify-content: right;
}

.remove-margin-top{
margin-top: 2px;
}
