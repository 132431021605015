.agent-profile-page-column {
    margin:20px 10px 10px 10px;
}
.agent-profile-page-column:first-child {
    margin-left:20px;
}
.agent-profile-page-column:last-child {
    margin-right:20px;
}
.colContainer {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.safeco-color {
    color: #1A1446;
}

.formContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.form-margins {
    margin-top: -60px;
    margin-bottom: -34px;
}

#card-edit-agent {
    background-color:  #ffffff;
    padding: 32px;
}

#card-edit-agent-small-padding {
    background-color:  #ffffff;
    padding: 8px;
}
.card-padding {
    margin-bottom: -32px;
    margin-left: 32px;
}

.loading-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.field-group-margin-top {
    margin-top: 25px;
}

.carrier-list-spacing{
    margin: 1px;
}
.carrier-li-spacing{
    margin: 0px;
}

.carrier-text-spacing{
    max-width:145px;
    word-wrap:break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }

.products-tile {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
 }
 .languages-wrapper {
     width:50%;
 }
 .languages-wrapper .lm-List {
     margin:0;
 }
.selected-products .product-and-languages-wrapper, .products-tile .product-and-languages-wrapper {
    margin-bottom:15px;
 }
 .selected-products .product-and-languages-wrapper:nth-child(even) {
    padding-left:10px;
 }
.product-and-languages-wrapper {
    display: inline-flex;
    width: 50% !important;
}

.product-text-spacing{
    margin-left: 4px;
 }

.additional-text-format {
    position: absolute;
    top: 22px;
    left: 165px;
}
.edit-btn-align-general {
    margin-top: -75px;
}

.edit-btn-align-absolute {
    position: absolute;
    top: 5px;
    right: 5px;
}

.filed-group-margin-items .lm-List {
    margin-top:0;
    width: 100%;
}
.product-spacing {
    margin-top: 5px;
}

.articleList{
    max-width:200px;
    word-wrap:break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.li{
    max-width:400px;
    word-wrap:break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkbox-align {
    margin-top: -10px;
}

.indent-content {
    padding-left: 30px;
    width: 100%;
}

.indent-content-less {
    padding-left: 20px;
    width: 100%;
}

.icon-normal-size {
    width: 32px;
    height: 32px;
}

.invisible-card {
    padding: 0%;
    border: none;
    margin-bottom: 0px;
    overflow: hidden;
    position: relative;

}

.lm-Card {
    padding: 16px;
}

/* manual override*/
.lm-Card-children {
    margin: 0px;
}

.inner-card {
    margin-top:20px;
}

.inner-card-top {
    position: absolute;
    top:0;
    min-height:200px;

}

.filed-group-margin-first {
    margin-top: 20px;
    position:static;
}

.filed-group-margin-general {
    margin-top: 24px;
}

.lm-FieldGroup-label.filed-group-margin-general-label, .filed-group-margin-first-label {
    margin-bottom: 20px;
}

.filed-group-margin-items {
    display:flex;
    flex-wrap: wrap;
}

.breadcrumb-line {
    font-size: 1rem;
    line-height: 1.6em;
    letter-spacing: normal;
    font-weight: 400;
}
.agencyProfile .breadcrumb-line {
    padding-left: 0;
}

.breadcrumb-line svg {
    margin-left: 8px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
}

.breadcrumb-links {
    color: #06748c!important;
    text-decoration: none;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    margin-top: 1rem;
}

.breadcrumb-links:hover {
    text-decoration: underline;
}

.breadcrumb-p {
    margin: 0;
    color: #777a7c;
    display: inline-block;
    margin-top: 1rem;
}

.iframe-preview {
    margin: 0;
    width: 1px;
    min-width: 100%;
    border:none;
}

.preview-modal {
    width: 70%;
    height: 80%;
    margin: 5% 5% 15% 15%;
}

.pageLayoutEdit {
    display: grid;
    justify-content: center;
    grid-template-columns: 20% 60% 20%;
}

.agentPageButtons {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 15px;
}

.checklist-title-background{
    background-color: #1A1446;
    color: #1A1446 !important;
    box-shadow: 0 1px 4px 0;
    height:85px;
    margin-bottom:10px;
}

.checklist-title-text{
    margin-top: 0px;
    margin-bottom: 0px;
    top: 6px;
    position: relative;
    color:white;
    padding: 19px 0;
    text-align: center;
}

#resetCanvas {
    display: none;
}

.cropAndResetLinks {
    position: absolute;
    cursor: pointer;
    height: 25.59px;
    bottom: 0;
    z-index: 1;
}

.supportedCommsHeader .lm-FieldGroup-label {
    margin-bottom: 0px;
}

#formField {
    margin-top: 0;
    margin-bottom: 0;
}

.rowContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
}

.flexbox-container {
    display: flex;
    flex-direction: row;
}

.listItem{
    line-height: normal;
}

.paragraph-style{
    padding: 1%;

}

.overlay{

    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
.processing{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }