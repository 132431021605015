.modal-body{
    margin-top: 2rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.modal-footer{
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.modal-heading{
    font-family: Roboto,Arial,sans-serif;
    font-size: 2rem !important;
    font-weight: 400 !important;
    color: #343741;
}
.col-gutters{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 8px;
}
.button-container{
    padding-top: .5rem !important;
    padding-bottom: 0px !important;
}
.trashable {
    display:flex;
    align-items:center;
}
.trashable div:first-child {
    flex-grow:1;
}

.trashable svg {
    margin:1rem;
}

.trashable svg:hover {
    cursor:pointer;
}
.rendered-cols{
    margin-bottom: .5rem;
}
.header-button {
    margin-right: 2rem !important;
}