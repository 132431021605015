
.safeco-color {
    color: #1A1446;
}

.space-input-left {
    margin-left: 10px;
}

.font {
    font-style: italic;
}

.solid {
    margin-top: 32px;
    border-top: 1px solid #bbb;
}

.form-align-top {
    margin-top: 0px;
}

.form-align-general {
    margin-top: 24px;
}

.remove-margin-bottom{
    margin-top: 2px;
}

.agent-contact-information {
    width: 100%;
}

.agent-contact-information .lm-Card {
    height:100%;
}

.lm-Card-title+.lm-Card-body {
    margin-top:20px;
}
.contact-information-section-edit-row:first-child .lm-Heading {
    margin-top:0;
}
.contact-information-subsection {
    padding: 0 30px 0 30px;
    width:100%;
}

.contact-information-subsection .lm-Heading {
    margin-top:0px;
    margin-bottom:0px;
}

.contact-information-subsection .phone-row {
    display: flex;
    justify-content: space-between;
}

.contact-information-section-edit-row {
    display: flex;
    justify-content: space-between;
    align-items:center;
    position:relative;
}

.add-additional-phone {
    display: flex;
    margin-top: 10px;
}